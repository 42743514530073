import api from '@eencloud/eewc-components/src/service/api';
import { Edition, EditionId } from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useEditionsStore = defineStore('editions', function () {
  const editions = ref<Edition[] | undefined>();
  const currentEdition = ref<Edition | undefined>();
  async function getEditions() {
    try {
      editions.value = (await api.getEditions())?.results;
    } catch (error) {
      console.error(error);
    }
  }

  async function getEdition(editionId: EditionId) {
    try {
      currentEdition.value = await api.getEdition(editionId);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    getEdition,
    currentEdition,
    editions,
    getEditions,
  };
});
