import { ANALYTIC_TYPE, PERFORMED_ON, UPDATED_CONFIGURATION } from '@/pages/Cameras/analyticSetting/constants';
import { CameraTunnelResponse } from '@/pages/Cameras/types';
import api from '@eencloud/eewc-components/src/service/api';
import {
  AnalyticsSettingsGet,
  AnalyticsType,
  CameraTunnelParams,
  ObjectDetectionConfigurationGet,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMessagingStore } from './messaging';

export const useAnalyticSettingsStore = defineStore('analyticSettings', () => {
  const messagingStore = useMessagingStore();

  // the store will have the analyticSettings information for a camera
  const analyticSettings = ref<AnalyticsSettingsGet[]>([]);
  const objectDetectionConfig = ref<ObjectDetectionConfigurationGet>({});
  const updatedAnalyticSettingsArray = ref<UPDATED_CONFIGURATION[]>([]);
  const loading = ref(false);
  const motionDetectionSelectedRegion = ref(0);
  const vehicleDetectionSelectedRegion = ref(0);
  const cameraTunnelResult = ref<CameraTunnelResponse | null>(null);
  const DEFAULT_SELECTED_REGIONS = {
    [ANALYTIC_TYPE.PERSON_DETECTION]: 0,
    [ANALYTIC_TYPE.VEHICLE_DETECTION]: 0,
    [ANALYTIC_TYPE.PPE_DETECTION]: 0,
  };
  const showHiddenAnalytics = ref(false);
  const selectedRegions = ref(DEFAULT_SELECTED_REGIONS);
  const showLPRBridgeAdvanceSettings = ref(false);

  async function getAnalyticsSettings(cameraId: string): Promise<void> {
    loading.value = true;
    try {
      const res = await api.getAnalyticsSettings(cameraId);
      analyticSettings.value = res?.results || [];
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  async function bulkUpdateAnalyticSettings(cameraId: string): Promise<boolean> {
    const modifiedSettings = updatedAnalyticSettingsArray.value
      .filter((item) => item.isDirty)
      .map((item) => {
        const data = {
          type: item.id as AnalyticsType,
          enabled: item.performedOn !== PERFORMED_ON.DISABLED,
          configuration:
            item.selectedConfigurationIndex !== undefined && item.configuration?.length
              ? item.configuration[item.selectedConfigurationIndex]
              : undefined,
        };
        if (data.configuration?.regions?.length) {
          data.configuration?.regions?.forEach((region) => delete region.color);
        }
        return data;
      });

    let isSuccessful = false;
    if (modifiedSettings.length) {
      const res = await api.bulkUpdateAnalyticsSettings(cameraId, modifiedSettings);
      if (res) {
        isSuccessful = true;
        Object.keys(res)?.forEach((id) => {
          if (res[id].status === 204) {
            const settings = updatedAnalyticSettingsArray.value.find((item) => item.id === id);
            if (settings) {
              settings.isDirty = false;
            }
          } else {
            messagingStore.addNotification({
              iconColor: 'negative',
              icon: '$icon_attention',
              title: res[id].error.status,
              body: `${id}: ${res[id].error.message}`,
            });
          }
          if (res[id].status !== 204 && isSuccessful) {
            isSuccessful = false;
          }
        });
      } else if (isSuccessful) {
        isSuccessful = false;
      }
    }
    return isSuccessful;
  }

  function reset() {
    analyticSettings.value = [];
    updatedAnalyticSettingsArray.value = [];
    motionDetectionSelectedRegion.value = 0;
    selectedRegions.value = DEFAULT_SELECTED_REGIONS;
  }

  const isLoadingcameraTunnel = ref(false);
  const isCameraTunnelError = ref(false);

  async function getCameraTunnel(
    params: CameraTunnelParams,
    authToken: string,
    controller?: AbortController,
    cancelReq = false
  ) {
    try {
      isLoadingcameraTunnel.value = true;
      if (cancelReq && controller) {
        controller.abort();
      }

      const response = await api.getCameraTunnel(params, authToken, controller?.signal);

      cameraTunnelResult.value = response?.data ?? {};
    } catch (error) {
      cameraTunnelResult.value = null;
      isCameraTunnelError.value = true;
    } finally {
      isLoadingcameraTunnel.value = false;
    }
  }
  return {
    analyticSettings,
    bulkUpdateAnalyticSettings,
    getAnalyticsSettings,
    loading,
    motionDetectionSelectedRegion,
    objectDetectionConfig,
    reset,
    selectedRegions,
    showHiddenAnalytics,
    updatedAnalyticSettingsArray,
    vehicleDetectionSelectedRegion,
    getCameraTunnel,
    cameraTunnelResult,
    isLoadingcameraTunnel,
    isCameraTunnelError,
    showLPRBridgeAdvanceSettings,
  };
});
