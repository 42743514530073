<template>
  <div>
    <eewc-navigation-topbar
      v-if="!appState.externalBrowser && !appState.isFullScreen && !appState.isIFrame"
      header-title="Dashboard"
      :top-bar-breadcrumbs="appState.topBarBreadcrumbs"
      :clock-data="{ value: state.clockValue, timeZone: state.timeZone }"
      :return-arrow="false"
      :new-notification="false"
      :fullscreen-tooltip="t('Fullscreen (f)')"
      @emit-fullscreen="enterFullScreen()"
    >
      <eewc-button-common
        class="mr-8"
        color="accent"
        @click="state.window.open('http://login.eagleeyenetworks.com')"
      >
        {{ t('Classic interface') }}
      </eewc-button-common>
    </eewc-navigation-topbar>
    <eewc-navigation-menubar
      v-if="!appState.externalBrowser && !appState.isFullScreen && !appState.isIFrame"
      :items="navigationData.items"
      help-center=""
      :help-items="[]"
      email=""
      :user-items="navigationData.userItems"
      :phone="navigationData.phone"
      :user-name="navigationData.userName"
      :logo-small="navigationData.logoSmall"
      :logo-small-width="navigationData.logoSmallWidth"
      :logo-small-height="navigationData.logoSmallHeight"
      :logo-big="navigationData.logoBig"
      :logo-big-width="navigationData.logoBigWidth"
      :logo-big-height="navigationData.logoBigHeight"
      :logo-margin-s-m="navigationData.logoMarginSM"
      :logo-margin-m-d="navigationData.logoMarginMD"
      :logo-margin-l-g="navigationData.logoMarginLG"
      :logo-margin-x-l="navigationData.logoMarginXL"
      :tooltips="navigationData.tooltips"
      :is-e-e-n="accountStore.isEEN"
      support-email=""
      support-phone=""
      @logout="handleLogout"
      @get-subItemId-clicked="assignItemClickedtoRouter"
    />
    <v-container
      fluid
      class="container-fluid"
    >
      <v-main
        id="main"
        class="container-main"
      >
        <div
          class="full-screen-wrapper"
          :class="{ 'full-screen--enabled': appState.isFullScreen }"
        >
          <router-view
            v-if="routeActive('Help center') || routeActive('External help center')"
            :key="$route.fullPath"
          />
          <eewc-empty-state
            v-else
            :icon-width="120"
            :icon-height="99"
            :max-width="527"
            icon="$icon_work_in_progress"
            :title="t('Work in progress')"
            :text="
              t(
                'We\'re working hard to create a new Reseller experience. In the meantime, you can sign in to an end-user account'
              )
            "
          >
            <template #action>
              <eewc-button-common
                color="primary"
                @click="handleLogout"
              >
                {{ t('Sign in as end-user') }}
              </eewc-button-common>
            </template>
          </eewc-empty-state>
        </div>
      </v-main>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, onBeforeUnmount, ref } from 'vue';

import { useUsersStore, useAuthStateStore, useAccountStore, useAppStateStore } from '@/stores';
import { t } from '@/plugins/i18n.ts';
import { goFullScreen, removeMilliseconds } from '@/service/helpers';
import router from '@/service/router';

import { BUSINESS_PORTAl_PROD_URL, BUSINESS_PORTAl_TEST_URL } from '@/constants';

const usersStore = useUsersStore();
const authStateStore = useAuthStateStore();
const accountStore = useAccountStore();
const appState = useAppStateStore();

const state = reactive({
  clockValue: '',
  window: window,
  clockInterval: undefined,
  timeZone: computed(() => usersStore.currentUserTimezone),
});
const isWhiteLabelEnabled = accountStore?.account?.brandingInfo?.isWhiteLabelEnabled;
const activeRoute = ref(router.currentRoute.name);
const activeRouteParam = ref(router.currentRoute.params.id);
const showHiddenFeatures = computed(() => appState.showHiddenFeatures);

function enterFullScreen() {
  goFullScreen();
  appState.updateIsFullScreen(true);
}

function exitFullScreen() {
  if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
    appState.updateIsFullScreen(false);
  }
}

onMounted(() => {
  document.addEventListener('fullscreenchange', exitFullScreen, false);
  state.clockInterval = setInterval(
    () => (state.clockValue = removeMilliseconds(new Date().toEENAccountTimeStamp().slice(10))),
    1000
  );
});
onBeforeUnmount(() => {
  clearInterval(state.clockInterval);
});
function assignItemClickedtoRouter(itemIndex, subItemIndex) {
  const navigationItem = navigationData.items[itemIndex];
  const route = { name: navigationItem.route };
  const validParam = subItemIndex !== undefined;
  const id = validParam ? navigationItem.subItems[subItemIndex].id : null;

  if (
    router.currentRoute.name !== navigationItem.route ||
    (router.currentRoute.name === navigationItem.route && validParam && router.currentRoute.params?.id !== id)
  ) {
    router.push(route);
  }
}

const navigationData = reactive({
  items: computed(() => {
    return [
      {
        icon: '$icon_dashboard',
        name: t('Dashboard'),
        active: !routeActive('Help center'),
        subItems: [],
        route: 'Dashboard',
      },
      {
        icon: '$icon_user',
        name: t('Users'),
        active: false,
        disable: true,
        subItems: [],
        route: 'Users',
      },
    ].filter((item) => item);
  }),
  userName: computed(() => usersStore.currentUserDisplayName),
  userItems: computed(() => {
    const items = [
      !isWhiteLabelEnabled && {
        name: t('Help center'),
        icon: '$icon_help',
        click: function () {
          router.push({ name: 'Help center' });
        },
        active: routeActive('Help center'),
      },
      {
        name: t('Business portal'),
        icon: '$icon_orders',
        click: () => {
          goToBusinessPortal();
        },
      },
      {
        name: t('Logout'),
        icon: '$icon_logout',
        click: () => {
          authStateStore.logout();
        },
      },
    ];
    return items.filter((item) => item);
  }),
  phone: '+31(0)20 261 04 6',
  logoSmall: 'logo_een_small.svg',
  logoSmallWidth: '40px',
  logoSmallHeight: '40px',
  logoBig: 'logo_een_big.svg',
  logoBigWidth: '120px',
  logoBigHeight: '44px',
  logoMarginSM: 'margin: 12px 4px',
  logoMarginMD: 'margin: 12px 8px',
  logoMarginLG: 'margin: 10px 56px 21px 38px',
  logoMarginXL: 'margin: 10px 76px 21px 59px',
  companyName: 'Eagle Eye Networks',
  tooltips: {
    emailTooltip: t('Email'),
    phoneTooltip: t('Phone'),
    expandTooltip: t('Expand'),
    collapseTooltip: t('Collapse'),
    profileTooltip: t('Your profile'),
  },
});

function goToBusinessPortal() {
  const REDIRECT_URL = config.is_production ? BUSINESS_PORTAl_PROD_URL : BUSINESS_PORTAl_TEST_URL;
  window.open(REDIRECT_URL, '_blank');
}

function handleLogout() {
  authStateStore.logout();
}

function routeActive(routeName) {
  return activeRoute.value === routeName;
}

router.afterEach((to) => {
  activeRoute.value = to.name;
  activeRouteParam.value = to.params.id;
});
</script>

<style lang="scss" scoped>
.container-fluid {
  height: 100%;
  padding: 0;
}
.container-main {
  height: 100%;
}
.full-screen-wrapper {
  background: white;
  padding: 20px 32px 0 20px !important;
  @media (display-mode: fullscreen) {
    overflow: auto;
    height: 100vh;
  }
}
</style>
