import { onMounted, onUnmounted, watch, ref } from 'vue';
import {
  useUsersStore,
  useCamerasStore,
  useLayoutsStore,
  usePtzStore,
  useCameraStatusStore,
  useAuthStateStore,
} from '../stores';
import { WebSocketClient } from '@/service/WebSocketClient';
import _ from 'lodash';
import { AnntType, ApiLayout, FLASH_DURATION } from '@eencloud/eewc-components/src/service/api-types';
import { authKey } from '@eencloud/eewc-components/src/service/auth.js';
import { getPlainAuthKeyFromJwt } from '@eencloud/eewc-components/src/utils/helpers.ts';
import { useWebSocketStore } from '@/stores/webSocket';

export default function useWebSocketEvents() {
  const usersStore = useUsersStore();
  const camerasStore = useCamerasStore();
  const layoutsStore = useLayoutsStore();
  const ptzStore = usePtzStore();
  const cameraStatusStore = useCameraStatusStore();
  const closeSocket = ref();
  const socketConnectionDate = ref<Date>();
  const authStateStore = useAuthStateStore();
  const webSocketStore = useWebSocketStore();
  const newLayoutCreatedEvents = ref<string[]>([]);

  watch(
    () => [usersStore.currentUser?.accountId],
    (oldVal, newVal) => {
      oldVal !== newVal && setupWebSocketClient();
    }
  );

  watch(
    () => authStateStore.loggedIn,
    (newVal, oldVal) => {
      if (newVal !== oldVal && newVal) {
        setupWebSocketClient();
      }
    }
  );
  onMounted(() => {
    setupWebSocketClient();
  });

  onUnmounted(() => {
    closeSocket.value && closeSocket.value();
  });

  function sendSubscription() {
    if (usersStore.currentUser?.accountId) {
      const resource = {};
      resource[usersStore.currentUser.accountId] = {
        resource: ['event'],
        event: ['AEEC', 'AEED', 'AEEL'],
      };
      return JSON.stringify({ cameras: resource });
    }
  }

  function setupWebSocketClient() {
    if (!authKey()) return;
    const plainAuthToken = getPlainAuthKeyFromJwt(authKey());
    if (usersStore.currentUser && usersStore.currentUser.accountId) {
      console.log('Setting up web socket client');
      closeSocket.value && closeSocket.value();
      const { connect, disconnect, ws } = WebSocketClient(
        usersStore.currentUser.accountId,
        sendSubscription(),
        notify,
        null
      );
      closeSocket.value = disconnect;
      socketConnectionDate.value = new Date();
      console.log('And now connecting');
      if (plainAuthToken) {
        connect(plainAuthToken);
        webSocketStore.setWebSocketClient(ws);
      } else {
        console.error('Error while getting plain auth key from jwt');
      }
    }
  }

  function notify(response: any) {
    const dataStr = response.data.replace(/NaN/g, 'null');

    const data = JSON.parse(dataStr);
    if (data === undefined || data.status_code !== 200) {
      return;
    }

    Object.keys(data.data).map(function (key) {
      //parsing the events
      data.data[key].event &&
        Object.keys(data.data[key].event).forEach((event) => parseEvents(event, data.data[key].event));

      if (data.data[key].status) {
        parseCameraStatus(key, data.data[key].status);
        parseRecordingStatus(key, data.data[key].status);
      }
    });
  }

  function parseCameraStatus(deviceId: string, status: number) {
    if (status === 0) {
      //no change in status
      return;
    }

    const internetOffline = getStatusFromBitMask(status, 20);
    const online = getStatusFromBitMask(status, 18);
    const off = getStatusFromBitMask(status, 17);
    const passwordNeeded = getStatusFromBitMask(status, 8);

    if (off === 0) {
      camerasStore.updateCameraStatus(deviceId, 'off');
      return;
    }

    if (internetOffline === 0) {
      camerasStore.updateCameraStatus(deviceId, 'bridgeOffline'); //internetOffline
      return;
    }

    if (online === 1) {
      camerasStore.updateCameraStatus(deviceId, 'online');
      return;
    }

    if (passwordNeeded === 1) {
      camerasStore.updateCameraStatus(deviceId, 'invalidCredentials');
      return;
    }

    camerasStore.updateCameraStatus(deviceId, 'deviceOffline');
  }

  function parseRecordingStatus(deviceId: string, status: number) {
    const recording = getStatusFromBitMask(status, 19);
    const recordingInValidState = getStatusFromBitMask(status, 16);

    if (recording === 1) {
      // recording on
      cameraStatusStore.updateRecordingStatus(deviceId, 'on');
      return;
    }

    if (recordingInValidState === 1) {
      // no change in status
      return;
    }

    cameraStatusStore.updateRecordingStatus(deviceId, 'off'); // recording off
  }

  function getStatusFromBitMask(status: number, mask: number) {
    return (status & (1 << mask)) >> mask;
  }

  async function parseEvents(eventType: string, data: any) {
    switch (eventType) {
      case 'AEEC': {
        // new layout created
        if (newLayoutCreatedEvents.value.includes(data[eventType]['layoutid'])) {
          // already processed event received, so ignoring it
          return;
        } else {
          newLayoutCreatedEvents.value.push(data[eventType]['layoutid']);
        }
        const eventTime = timestampToDate(data[eventType]['timestamp']);
        if (socketConnectionDate.value && eventTime < socketConnectionDate.value) {
          // AEEC received event: ignored as timestamp is old
          return;
        }
        layoutsStore.resetPagedLayouts();
        break;
      }

      case 'AEED': {
        // layout deleted
        const deletedLayoutId = data[eventType]['layoutid'];
        const matchedLayout = layoutsStore.pagedLayouts.find((layout) => layout.id === deletedLayoutId);
        if (matchedLayout) {
          layoutsStore.resetPagedLayouts();
        }
        break;
      }

      case 'AEEL': {
        // layout edited
        const editedLayoutId = data[eventType]['layoutid'].split('{')[0];
        const editedLayoutExist = layoutsStore.pagedLayouts.some((l) => l.id === editedLayoutId);
        if (editedLayoutExist && editedLayoutId) {
          const layout = (await layoutsStore.getLayout(editedLayoutId, 'effectivePermissions')) as ApiLayout;
          layout && layoutsStore.updateLayoutInStore(layout);
        }
        break;
      }

      case 'CZTS': {
        processCztsEvents(data[eventType]);
        break;
      }

      case 'PTZS': {
        const eventTime = timestampToDate(data[eventType]['timestamp']);
        if (
          (socketConnectionDate.value && eventTime < socketConnectionDate.value) ||
          !usersStore.currentUser?.permissions?.controlPTZ
        ) {
          // PTZS received event: ignored as timestamp is old
          return;
        }
        ptzStore.updatePtzStatus({
          cameraId: data[eventType].cameraid,
          x: data[eventType].x,
          y: data[eventType].y,
          z: data[eventType].z,
        });
        break;
      }

      case 'ANNT': {
        if (
          data[eventType].ns >= AnntType.ANNT_LINE_CROSS_NS &&
          data[eventType].ns <= AnntType.ANNT_TAMPERING_NS &&
          data[eventType].ns !== AnntType.ANNT_OBJECT_TRACKING_NS
        ) {
          const event = data[eventType];

          const date = timestampToDate(event.timestamp);
          if (new Date().getTime() - date.getTime() > 120000) {
            // discarding events which are older than 2 minutes (loitering), this is done in mobile
            return;
          }

          switch (event.ns) {
            case AnntType.ANNT_INTRUSION_DETECT_NS:
              ['in', 'enter'].includes(event.mpack.anaevent) &&
                cameraStatusStore.changeAnntStatus(event.cameraid, AnntType.ANNT_INTRUSION_DETECT_NS, 'active', true);
              event.mpack.anaevent === 'out' &&
                cameraStatusStore.changeAnntStatus(event.cameraid, AnntType.ANNT_INTRUSION_DETECT_NS, 'active', false);
              break;
            case AnntType.ANNT_LOITERING_NS:
              ['in', 'loitering'].includes(event.mpack.anaevent) &&
                cameraStatusStore.changeAnntStatus(event.cameraid, AnntType.ANNT_LOITERING_NS, 'active', true);
              event.mpack.anaevent === 'out' &&
                cameraStatusStore.changeAnntStatus(event.cameraid, AnntType.ANNT_LOITERING_NS, 'active', false);
              break;
            case AnntType.ANNT_TAMPERING_NS:
              if (event.mpack.anaevent === 'tamper') {
                cameraStatusStore.changeAnntStatus(event.cameraid, AnntType.ANNT_TAMPERING_NS, 'active', true);
                setTimeout(
                  () => cameraStatusStore.changeAnntStatus(event.cameraid, AnntType.ANNT_TAMPERING_NS, 'active', false),
                  FLASH_DURATION
                );
              }
              break;
            case AnntType.ANNT_LINE_CROSS_NS:
              cameraStatusStore.updateCrossingAnnCount(event.cameraid, event.mpack.anaevent, event.mpack.ananame);
              break;
            default:
              console.log('Unknown event ns received');
          }
        }
        break;
      }
      default:
        console.log('Unknown event reveived from websocket');
    }
  }
  function processCztsEvents(event: any) {
    const settings = event.settings.user_settings.settings;
    const cameraId = event.cameraid;
    if (
      !settings.active_application ||
      !settings.active_application.eenivi ||
      !settings.active_application.eenivi.features
    ) {
      return;
    }

    const supportedFeatures = settings.active_application.eenivi.features;
    let countingIds: string[] = [];
    let crossingIds: string[] = [];
    let isCrossing = false;
    let isCounting = false;

    Object.keys(supportedFeatures).forEach((key) => {
      if (key.indexOf('linecross-') !== -1) {
        const lines = supportedFeatures[key].lines;
        Object.keys(lines).map((lKey) => {
          let status = lines[lKey].line_type === 'counting'; //crossing.

          if (status) {
            countingIds = countingIds.concat([lines[lKey].id]);
            !isCounting && (isCounting = status);
          }

          status = lines[lKey].line_type === 'crossing'; //counting.
          if (status) {
            crossingIds = crossingIds.concat([lines[lKey].id]);
            !isCrossing && (isCrossing = status);
          }
        });
      }
    });
    cameraStatusStore.changeLineCrossAnntStatus(cameraId, 'crossing', 'enable', isCrossing, crossingIds);
    cameraStatusStore.changeLineCrossAnntStatus(cameraId, 'counting', 'enable', isCounting, countingIds);

    // Todo added purposefully for debugging, commented for time being
    // console.log("for camera - ", cameraId," isCrossing - ",isCrossing, " isCounting - ",isCounting);
    // console.log("for camera - ", cameraId," intrusion is ", !!supportedFeatures["intrusion-1"]);
    // console.log("for camera - ", cameraId," tamper is ", !!supportedFeatures["tamper-1"]);
    // console.log("for camera - ", cameraId," loitering is ", !!supportedFeatures["loitering-1"]);

    cameraStatusStore.changeAnntStatus(
      cameraId,
      AnntType.ANNT_INTRUSION_DETECT_NS,
      'enable',
      !!supportedFeatures['intrusion-1']
    );
    cameraStatusStore.changeAnntStatus(cameraId, AnntType.ANNT_TAMPERING_NS, 'enable', !!supportedFeatures['tamper-1']);
    cameraStatusStore.changeAnntStatus(
      cameraId,
      AnntType.ANNT_LOITERING_NS,
      'enable',
      !!supportedFeatures['loitering-1']
    );
  }

  function timestampToDate(ts: string) {
    const yy = parseInt(ts.substring(0, 4), 10);
    const mm = parseInt(ts.substring(4, 6), 10);
    const dd = parseInt(ts.substring(6, 8), 10);
    const hr = parseInt(ts.substring(8, 10), 10);
    const mn = parseInt(ts.substring(10, 12), 10);
    const sc = parseInt(ts.substring(12, 14), 10);
    const ms = parseInt(ts.substring(15), 10);
    const date = new Date(Date.UTC(yy, mm - 1, dd, hr, mn, sc, ms));
    return date;
  }
}
