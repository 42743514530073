import { computed, reactive, ref } from 'vue';
import posthog from 'posthog-js';

import _ from 'lodash';

import router from './router';
import { t } from '@/plugins/i18n.ts';
import { openInNewTab, navigate } from './helpers.ts';
import {
  useAccountStore,
  useCamerasStore,
  useLayoutsStore,
  useAppStateStore,
  useUsersStore,
  useTagsStore,
  useMessagingStore,
  useAuthStateStore,
  useFirstRespondersStore,
  useVSPersistedStateStore,
  useEditionsStore,
} from '@/stores';

import useSensorsNavigation from '@/pages/Sensors/navigation/useSensorsNavigation.ts';
import useVSPNavigation from '@/pages/VSP/navigation/useVSPNavigation.ts';
import usePOSNavigation from '@/pages/POS/navigation/usePOSNavigation';
import useResponderNavigation from '@/pages/FirstResponder/navigation/useResponderNavigation.ts';
import useAlertManagerNavigation from '@/pages/AlertManager/navigation/useAlertManagerNavigation.ts';
import { CLASSIC_WEB_APP_URL, LAST_USED_APP, WEB_INTERFACE } from '@/constants/index.ts';

function useNavigation() {
  const usersStore = useUsersStore();
  const camerasStore = useCamerasStore();
  const accountStore = useAccountStore();
  const layoutsStore = useLayoutsStore();
  const messagingStore = useMessagingStore();
  const tagsStore = useTagsStore();
  const appState = useAppStateStore();
  const authStateStore = useAuthStateStore();
  const videoSearchPersistedStore = useVSPersistedStateStore();
  const firstRespondersStore = useFirstRespondersStore();
  const editionStore = useEditionsStore();

  const isLogoutLoading = ref(false);
  const activeRoute = ref(router.currentRoute.name);
  const activeRouteParam = ref(router.currentRoute.params.id);

  const hasFirstResponders = computed(() => firstRespondersStore.firstResponders?.length);
  const hasRespondersShared = computed(() => accountStore.account?.firstResponders?.active);
  const showHiddenFeaturesRef = computed(() => appState.showHiddenFeatures);
  const isRolesEnabled = computed(() => accountStore.account?.roleSettings?.enabled);

  const { sensorsNavigationData, handleSensorsChildrenRoutes } = useSensorsNavigation(activeRoute);
  const { VSPNavigationData, handleVSPChildrenRoutes, VSP_SUB_ROUTES } = useVSPNavigation(activeRoute);
  const { POSNavigationData, handlePOSChildrenRoutes, POS_SUB_ROUTES } = usePOSNavigation(activeRoute);
  const { AlertManagerNavigationData, handleAlertManagerChildrenRoutes, ALERT_MANAGER_SUB_ROUTES } =
    useAlertManagerNavigation(activeRoute);

  const { responderNavigationData } = useResponderNavigation(activeRoute, activeRouteParam);

  const navigationData = reactive({
    items: computed(() => {
      const accountCapabilities = accountStore?.accountCapabilities;
      return [
        {
          icon: '$icon_video_search',
          name: t('Video search'),
          active: routeActive('VideoSearch'),
          subItems: [],
          route: 'VideoSearch',
        },
        {
          icon: '$icon_dashboard',
          name: t('Dashboard'),
          active: routeActive('Dashboard'),
          subItems: [],
          route: 'Dashboard',
        },
        {
          icon: '$icon_layouts',
          name: t('Layouts'),
          active: ['Layouts', 'All cameras layout', 'New layout'].includes(activeRoute.value),
          subItems: computedLayouts.value,
          route: 'Layouts',
          infiniteHandler: layoutsInfiniteHandler,
        },
        responderNavigationData.items && { ...responderNavigationData.items },
        {
          icon: '$icon_tags',
          name: t('Tags'),
          active: routeActive('Tags'),
          subItems: computedTags.value,
          route: 'Tags',
          infiniteHandler: tagsInfiniteHandler,
        },
        accountCapabilities?.locationGroups && {
          icon: '$icon_location',
          name: t('Sites'),
          active: routeActive('Sites') || routeActive('Floor plans') || routeActive('Site'),
          subItems: sitesSubMenuItems(),
          route: 'Sites',
        },
        AlertManagerNavigationData.items && { ...AlertManagerNavigationData.items },
        {
          icon: '$icon_map',
          name: t('Map'),
          active: routeActive('Map'),
          subItems: [],
          route: 'Map',
        },
        VSPNavigationData.items &&
          accountStore.account?.packages?.includes('een.vsp') && { ...VSPNavigationData.items },
        accountCapabilities?.pointOfSale && POSNavigationData.items && { ...POSNavigationData.items },
        sensorsNavigationData.items && { ...sensorsNavigationData.items },
        {
          icon: '$icon_archive',
          name: t('Files'),
          active: routeActive('Archive') || routeActive('Download') || routeActive('Reports'),
          route: usersStore?.permissions?.viewArchive ? 'Archive' : 'Download',
          subItems: fileSubMenuItems(),
        },
        adminSubMenuItems().length && {
          icon: '$icon_admin',
          name: t('Admin'),
          active:
            routeActive('Users') ||
            routeActive('Roles') ||
            routeActive('Delegations') ||
            routeActive('Audit log') ||
            routeActive('Account settings') ||
            routeActive('User settings'),
          route: usersStore?.permissions?.editUsers ? 'Users' : 'Account settings',
          subItems: adminSubMenuItems(),
        },
      ].filter((item) => item);
    }),
    userName: computed(() => usersStore.currentUserDisplayName),
    helpCenter: t('Help center'),
    helpItems: [
      {
        name: t("What's new"),
        icon: '$icon_article',
        click: () => ({}),
      },
      {
        name: t('Feedback'),
        icon: '$icon_feedback',
        click: () => ({}),
      },
      {
        name: t('Contact support'),
        icon: '$icon_contact_support',
        click: () => ({}),
      },
      {
        name: t('Help'),
        icon: '$icon_help',
        click: function () {
          openInNewTab('https://support.een.com/portal/en/home');
        },
      },
    ],
    userItems: computed(() => {
      const items = [
        {
          name: t('My notifications'),
          icon: '$icon_notification',
          click: function () {
            navigate({ name: 'Notifications history' });
          },
          active: routeActive('Notifications history'),
        },
        {
          name: t('My profile'),
          icon: '$icon_user',
          click: function () {
            navigate({ name: 'My profile' });
          },
          active: routeActive('My profile'),
        },
        {
          name: t('Classic interface'),
          icon: '$icon_een_logo',
          click: () => {
            goToClassicWebApp();
          },
        },
        hasFirstResponders.value && {
          name: !hasRespondersShared.value ? t('Activate responder share') : t('Deactivate responder share'),
          icon: '$icon_first_responder',
          click: () => {
            handleFirstResponderShare();
          },
        },
        {
          name: t('Logout'),
          icon: '$icon_logout',
          click: () => {
            handleLogOut();
          },
          isLoading: isLogoutLoading.value,
        },
      ];
      return items.filter((item) => item);
    }),
    email: computed(() => usersStore.currentUser?.email || ''),
    phone: computed(() => usersStore.currentUser?.phone || ''),
    supportEmail: computed(
      () => (accountStore.applyBranding && accountStore?.account?.brandingInfo?.supportEmail) || ''
    ),
    supportPhone: computed(
      () => (accountStore.applyBranding && accountStore?.account?.brandingInfo?.supportPhone) || ''
    ),
    logoSmall: getSmallLogoBySite(),
    logoSmallWidth: '40px',
    logoSmallHeight: '40px',
    logoBig: getBigLogoBySite(),
    logoBigWidth: '120px',
    logoBigHeight: '44px',
    logoMarginSM: 'margin: 12px 4px',
    logoMarginMD: 'margin: 12px 8px',
    logoMarginLG: 'margin: 4px 46px 20px 22px',
    logoMarginXL: 'margin: 10px 76px 21px 59px',
    companyName: 'Eagle Eye Networks',
    tooltips: {
      emailTooltip: t('Email'),
      phoneTooltip: t('Phone'),
      expandTooltip: t('Expand'),
      collapseTooltip: t('Collapse'),
      profileTooltip: t('Your profile'),
    },
  });
  const computedLayouts = computed(() => {
    const allCamerasObject = {
      id: 'all',
      name: t('All cameras'),
      active: activeRoute.value === 'Layouts' && activeRouteParam.value === 'all',
    };

    const newLayoutObject = {
      id: 'new-layout',
      name: t('New layout'),
      active: activeRoute.value === 'Layouts' && activeRouteParam.value === 'new-layout',
    };

    if (layoutsStore.pagedLayouts.length) {
      const layouts = layoutsStore.pagedLayouts.map((layout) => {
        return {
          id: layout.id,
          name: layout.name,
          active: activeRouteParam.value === layout.id,
        };
      });
      return [allCamerasObject, ...layouts];
    } else if (
      layoutsStore.totalLayoutsSize === 0 &&
      (camerasStore.pagedCameras.length || activeRoute.value === 'Layouts')
    ) {
      return [allCamerasObject, newLayoutObject];
    } else return [];
  });

  const computedTags = computed(() => {
    if (tagsStore.pagedTags.length) {
      const allTagsObject = {
        id: 'all',
        name: t('All tags'),
        active: activeRoute.value === 'Tags' && activeRouteParam.value === 'all',
      };

      const tagItems = tagsStore.pagedTags.map((tag) => ({
        id: tag.name,
        name: tag.name,
        active: activeRouteParam.value === tag.name,
      }));

      return [allTagsObject, ...tagItems];
    } else return [];
  });

  function navbarInfiniteHandler(parentIndex, state) {
    const navigationItem = navigationData.items[parentIndex];
    if (!navigationItem.infiniteHandler) return;
    navigationItem.infiniteHandler(state);
  }

  function getBigLogoBySite() {
    const domain = localStorage.getItem('domain') || 'EEN';
    switch (domain) {
      case 'CAPTURE':
        return '/logo_capture_big.svg';
      case 'WISENET':
        return '/logo_wisenet_big.svg';
      case 'MOBOTIX':
        return '/logo_mobotix_big.svg';
      default:
        return '/logo_een_big.svg';
    }
  }

  function getSmallLogoBySite() {
    const domain = localStorage.getItem('domain') || 'EEN';
    if (domain === 'EEN') {
      return '/logo_een_small.svg';
    } else {
      return '';
    }
  }

  async function handleLogOut() {
    try {
      authStateStore.loggedIn = false;
      isLogoutLoading.value = true;
      videoSearchPersistedStore.updateShowHiddenFaceMatchFeature(false);
      authStateStore.logout();
    } catch (error) {
      // if api call fails, set loading to false
      isLogoutLoading.value = false;
      authStateStore.loggedIn = true;
      console.log(error);
    }
  }

  async function getPosthogParams() {
    const result = await Promise.allSettled([
      accountStore.fetchAccountWithCustomParam({ include: 'resourceCounts' }),
      editionStore.getEdition(accountStore.account?.editionId),
    ]);
    const res = result[0].value;
    const { multiCameras, cameras } = res.resourceCounts;
    return {
      user_id: usersStore.currentUser.id,
      edition: editionStore.currentEdition.name,
      camera_count: multiCameras + cameras,
    };
  }

  async function goToClassicWebApp() {
    const params = await getPosthogParams();
    posthog.capture('switched_to_classic_web_app', { params });
    const data = {
      data: { value: WEB_INTERFACE.CLASSIC },
    };
    await usersStore.updateUserPreference(LAST_USED_APP, data);
    window.open(CLASSIC_WEB_APP_URL, '_self');
  }

  async function handleFirstResponderShare() {
    const newResponderShareStatus = !hasRespondersShared.value;

    const status = await accountStore.patchAccount('self', {
      firstResponders: { active: newResponderShareStatus },
    });

    if (status === 204) {
      const bodyText = newResponderShareStatus
        ? t('The first responder has activated, and selected responders have been notified.')
        : t('The first responder has deactivated.');

      messagingStore.showSuccessMessage(bodyText);
      fetchAccountData();
    }
  }

  function fetchAccountData() {
    accountStore.fetchAccount({
      include: 'timeZone,workingHours,brandingInfo,packages,security,firstResponders',
    });
  }

  async function layoutsInfiniteHandler(state) {
    if (layoutsStore.pagedLayoutsLoading === false) {
      layoutsStore.pagedLayoutInfiniteState = state;
      if (layoutsStore.pagedLayoutsNextPageToken === '') {
        return layoutsStore.pagedLayoutInfiniteState.completed();
      }
      layoutsStore.pagedLayoutsLoading = true;
      if (layoutsStore.pagedLayoutsNextPageToken === undefined) {
        layoutsStore.pagedLayouts = [];
      }

      const params = {
        pageToken: layoutsStore.pagedLayoutsNextPageToken,
        pageSize: 10,
        include: ['effectivePermissions'].toString(),
        sort: ['+rotationOrder'].toString(), //to fetch the layouts in the sorted order
      };
      const { layouts, nextPageToken } = await layoutsStore.getPagedLayouts(params);
      layoutsStore.pagedLayouts = [...layoutsStore.pagedLayouts, ...layouts];
      layoutsStore.pagedLayoutsLoading = false;
      layoutsStore.pagedLayoutsNextPageToken = nextPageToken;
      layoutsStore.pagedLayoutInfiniteState.loaded();
      if (nextPageToken === '') {
        layoutsStore.pagedLayoutInfiniteState.completed();
      }
    }
  }

  async function tagsInfiniteHandler(state) {
    tagsStore.pagedTagsInfiniteState = state;
    if (tagsStore.pagedTagsLoading === false) {
      if (tagsStore.pagedTagsNextPageToken === '') {
        return tagsStore.pagedTagsInfiniteState.completed();
      }
      tagsStore.pagedTagsLoading = true;
      if (tagsStore.pagedTagsNextPageToken === undefined) {
        tagsStore.pagedTags = [];
      }

      const params = {
        pageToken: tagsStore.pagedTagsNextPageToken,
        pageSize: 10,
      };
      const { tags, nextPageToken } = await tagsStore.getPagedTags(params);
      tagsStore.pagedTags = [...tagsStore.pagedTags, ...tags];
      tagsStore.pagedTagsLoading = false;
      tagsStore.pagedTagsNextPageToken = nextPageToken;
      tagsStore.pagedTagsInfiniteState.loaded();
      if (nextPageToken === '') {
        tagsStore.pagedTagsInfiniteState.completed();
      }
    }
  }

  function routeActive(routeName) {
    return activeRoute.value === routeName;
  }

  router.afterEach((to) => {
    activeRoute.value = to.name;
    activeRouteParam.value = to.params.id;
  });

  function assignItemClickedtoRouter(itemIndex, subItemIndex) {
    const navigationItem = navigationData.items[itemIndex];
    const route = { name: navigationItem.route };
    const validParam = subItemIndex !== undefined;
    const id = validParam ? navigationItem.subItems[subItemIndex].id : null;

    // eslint-disable-next-line
    gtag('event', 'Route', {
      route_clicked: navigationItem.route,
    });

    const isChildRoute = handleChildrenRoutes(navigationItem, id);
    if (isChildRoute) return;

    if (validParam) {
      route.params = { id };
    }

    const isVSPSubRoute =
      VSP_SUB_ROUTES.includes(router.currentRoute.name) && VSP_SUB_ROUTES.includes(navigationItem.route);

    if (isVSPSubRoute) {
      return;
    }

    const isPOSSubRoute =
      POS_SUB_ROUTES.includes(router.currentRoute.name) && POS_SUB_ROUTES.includes(navigationItem.route);

    if (isPOSSubRoute) {
      return;
    }

    const isAlertManagerSubRoute =
      ALERT_MANAGER_SUB_ROUTES.includes(router.currentRoute.name) &&
      ALERT_MANAGER_SUB_ROUTES.includes(navigationItem.route);

    if (isAlertManagerSubRoute) {
      return;
    }

    if (
      router.currentRoute.name !== navigationItem.route ||
      (router.currentRoute.name === navigationItem.route && validParam && router.currentRoute.params?.id !== id)
    ) {
      navigate(route);
    }
  }

  function handleChildrenRoutes(navigationItem, id) {
    if (navigationItem.route === 'Sensors') return handleSensorsChildrenRoutes(id);
    if (navigationItem.route === 'Archive') {
      switch (id) {
        case 'archive': {
          navigate(`/${navigationItem.route.toLowerCase()}/files`);
          return true;
        }
        case 'download': {
          navigate(`/${navigationItem.route.toLowerCase()}/download`);
          return true;
        }
        case 'reports': {
          router.push(`/${navigationItem.route.toLowerCase()}/reports`);
          return true;
        }
      }
    }
    if (navigationItem.route === 'Users') {
      switch (id) {
        case 'users': {
          navigate({ name: 'Users' });
          return true;
        }
        case 'roles': {
          navigate({ name: 'Roles' });
          return true;
        }
        case 'delegations': {
          navigate({ name: 'Delegations' });
          return true;
        }
        case 'audit': {
          navigate({ name: 'Audit log' });
          return true;
        }
        case 'account': {
          navigate({ name: 'Account settings' });
          return true;
        }
        default: {
          return false;
        }
      }
    }
    if (navigationItem.route === 'POS Transactions') return handlePOSChildrenRoutes(id);

    if (navigationItem.route === 'Sites') {
      switch (id) {
        case 'floorplans': {
          router.push({ name: 'Floor plans' });
          return true;
        }
      }
    }

    if (navigationItem.route === 'Search') return handleVSPChildrenRoutes(id);

    if (navigationItem.route === 'Alert actions' || navigationItem.route === 'Automations alerts')
      return handleAlertManagerChildrenRoutes(id);

    return false;
  }

  function sitesSubMenuItems() {
    return [
      {
        id: 'allsites',
        name: t('All sites'),
        active: ['Sites', 'Site'].includes(activeRoute.value),
      },
      accountStore?.accountCapabilities?.floorPlan && {
        id: 'floorplans',
        name: t('Floor plans'),
        active: activeRoute.value === 'Floor plans',
      },
    ].filter((item) => item);
  }

  function adminSubMenuItems() {
    const { upgradeEdition, editSharing, editAccounts, viewAuditLog, editUsers, administrator } =
      usersStore.permissions ?? {};
    return [
      editUsers && {
        id: 'users',
        name: t('Users'),
        active: ['User settings', 'Users'].includes(activeRoute.value),
      },
      editUsers &&
        isRolesEnabled.value && {
          id: 'roles',
          name: t('Roles'),
          active: activeRoute.value === 'Roles',
        },
      showHiddenFeaturesRef.value &&
        administrator &&
        accountStore.accountCapabilities.resellerDelegation && {
          id: 'delegations',
          name: t('Delegations'),
          active: activeRoute.value === 'Delegations',
        },
      viewAuditLog && {
        id: 'audit',
        name: t('Audit log'),
        active: activeRoute.value === 'Audit log',
      },
      (editAccounts || upgradeEdition || editSharing) && {
        id: 'account',
        name: t('Account settings'),
        active: activeRoute.value === 'Account settings',
      },
    ].filter((item) => item);
  }

  function fileSubMenuItems() {
    return [
      usersStore?.permissions?.viewArchive && {
        id: 'archive',
        name: t('Archive'),
        active: activeRoute.value === 'Archive',
      },
      {
        id: 'download',
        name: t('Downloads'),
        active: activeRoute.value === 'Download',
      },
      usersStore?.permissions?.viewArchive && {
        id: 'reports',
        name: t('Reports'),
        active: activeRoute.value === 'Reports',
      },
    ].filter((item) => item);
  }

  function onClickLogo() {
    navigate({ name: '/' });
  }

  function onShiftDoubleClickLogo() {
    messagingStore.addNotification({
      // eslint-disable-next-line no-undef
      title: `version ${config.version}`,
      icon: '$icon_info',
    });
  }

  return {
    assignItemClickedtoRouter,
    navigationData,
    activeRoute,
    onClickLogo,
    onShiftDoubleClickLogo,
    navbarInfiniteHandler,
    computedLayouts,
    computedTags,
    isLogoutLoading,
    goToClassicWebApp,
  };
}

export default useNavigation;
