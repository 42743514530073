export enum QueryKeys {
  // Cameras
  GET_CAMERA_SETTINGS = 'GET_CAMERA_SETTINGS',

  // Events
  GET_DEVICE_SUPPORTED_EVENTS = 'GET_DEVICE_SUPPORTED_EVENTS',
  GET_EVENT_METRICS = 'GET_EVENT_METRICS',
  GET_SYSTEM_SUPPORTED_EVENTS = 'GET_SYSTEM_SUPPORTED_EVENTS',

  //delegates
  GET_DELEGATES = 'GET_DELEGATES',
  GET_DELEGATE_GRANTS = 'GET_DELEGATE_GRANTS',

  // vsp
  VSP_IMAGE = 'VSP_IMAGE',
}
