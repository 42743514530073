import posthog from 'posthog-js';

export default {
  install(Vue) {
    posthog.init('phc_ziFxZGCxvRMdAJ9HaygHhUCrkVxqKwO9usoEeoTDOPU', {
      api_host: 'https://us.i.posthog.com',
      disable_session_recording: true, //Turn off session replay
      mask_all_text: true, //text masked in order to prevent personal info collection
      mask_all_element_attributes: true, //element attributes masked in order to prevent personal info collection
      capture_performance: true, // To capture performance metrics.These include Network Timing and Web Vitals.
    });
    posthog.group('webapp_type', 'vms_enhanced');
    Vue.prototype.$posthog = posthog;
  },
};
