import { icons } from '@eencloud/eewc-components/src/assets/icons/icons';
import Theme from '@eencloud/eewc-components/src/service/themes';
import { createVuetify } from '@logue/vue2-helpers/vuetify';

const domainTheme = localStorage.getItem('domain') || 'EEN';
const theme = new Theme(domainTheme).colors();

export default createVuetify({
  icons,
  theme,
});
