import posthog from 'posthog-js';

import { ApiUserMain } from '@eencloud/eewc-components/src/service/api-types';

export default function useIdentifyAndTrackUser() {
  function identifyAndTrackUser(user: ApiUserMain) {
    if (!user?.id) return;

    const isInternalUser = user.email.includes('@een.com');
    posthog.identify(user.id);

    posthog.people.set({ user_type: isInternalUser ? 'internal' : 'external' });
  }

  function resetTracking() {
    posthog.reset();
  }

  return { identifyAndTrackUser, resetTracking };
}
