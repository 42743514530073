import { computed } from 'vue';
import { useGatewaysStore } from '@/stores';

export function useHasSensors() {
  const gatewaysStore = useGatewaysStore();

  const hasSensors = computed<boolean>(() => {
    return (
      Array.isArray(gatewaysStore.paginatedGateways?.results) && gatewaysStore.paginatedGateways?.results.length > 0
    );
  });

  return { hasSensors };
}
