import { ref } from 'vue';
import { defineStore } from 'pinia';
import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiGatewayCreate,
  ApiGatewayUpdate,
  ApiGatewayWithIncludes,
  ApiPaginatedGatewayResponse,
  GetGatewaysParams,
} from '@eencloud/eewc-components/src/service/api-types/gateways';
import { t } from '@/plugins/i18n.ts';
import { useMessagingStore } from '@/stores';

export const useGatewaysStore = defineStore('gateways', () => {
  const messagingStore = useMessagingStore();

  const loadingGateways = ref(false);

  const gateways = ref<ApiGatewayWithIncludes[]>([]);
  const paginatedGateways = ref<ApiPaginatedGatewayResponse>();
  const gateway = ref<ApiGatewayWithIncludes>();

  async function addGateway(payload: ApiGatewayCreate): Promise<boolean> {
    try {
      loadingGateways.value = true;
      const data = await api.addSensorGateway(payload);
      if (!data) {
        return false;
      }
      const notificationBodyText = t('The {gatewayName} gateway was successfully added to Dashboard.', {
        gatewayName: `<strong>${data.name}</strong>`,
      });
      messagingStore.showSuccessMessage(notificationBodyText);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      loadingGateways.value = false;
    }
  }

  async function fetchAllGateways(params: GetGatewaysParams): Promise<void> {
    try {
      loadingGateways.value = true;
      let pageToken: string | undefined;
      let allResults: ApiGatewayWithIncludes[] = [];
      while (pageToken !== '') {
        const data = await api.getSensorGateways({ ...params, pageToken: pageToken as string, pageSize: 500 });
        if (data) {
          pageToken = data.nextPageToken;
          const results = data.results as ApiGatewayWithIncludes[];
          allResults = allResults.concat(results);
        } else break;
      }
      gateways.value = allResults;
    } catch (error) {
      console.error(error);
    } finally {
      loadingGateways.value = false;
    }
  }

  async function fetchPaginatedGateways(params: GetGatewaysParams): Promise<void> {
    try {
      loadingGateways.value = true;
      const data = await api.getSensorGateways({ ...params });
      paginatedGateways.value = data as ApiPaginatedGatewayResponse;
    } catch (error) {
      console.error(error);
    } finally {
      loadingGateways.value = false;
    }
  }

  async function fetchSingleGateway(params: GetGatewaysParams, id: string): Promise<void> {
    try {
      loadingGateways.value = true;
      const data = await api.getSensorGateway(params, id);
      gateway.value = data as ApiGatewayWithIncludes;
    } catch (error) {
      console.error(error);
    } finally {
      loadingGateways.value = false;
    }
  }

  async function getGateway(params: GetGatewaysParams, id: string): Promise<ApiGatewayWithIncludes | undefined> {
    try {
      loadingGateways.value = true;
      const data = await api.getSensorGateway(params, id);
      return data as ApiGatewayWithIncludes;
    } catch (error) {
      console.error(error);
    } finally {
      loadingGateways.value = false;
    }
  }

  async function updateGateway(payload: ApiGatewayUpdate, id: string): Promise<boolean> {
    try {
      loadingGateways.value = true;
      const status = await api.updateSensorGateway(payload, id);

      if (status === 204) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      loadingGateways.value = false;
    }
  }

  async function deleteGateway(id: string): Promise<boolean> {
    try {
      loadingGateways.value = true;
      const status = await api.deleteSensorGateway(id);

      if (status === 204) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      loadingGateways.value = false;
    }
  }

  return {
    loadingGateways,
    gateways,
    paginatedGateways,
    gateway,
    addGateway,
    fetchAllGateways,
    fetchPaginatedGateways,
    fetchSingleGateway,
    getGateway,
    updateGateway,
    deleteGateway,
  };
});
